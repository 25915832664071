
#pix-page {

    h1 {
        font-size: 32px;
        color: #00A988;
        line-height: 40px;
        margin-bottom: 15px;
    }
    
    .info-pix {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 45px;
    }
    
    .box-qrcode {
        background: white;
        padding: 16px;
        max-width: 300px;
        width: 100%;
        margin-bottom: 30px;
        border: 1px solid black;
        border-radius: 12px;
    
        .qrCode { 
            width: 100%;
            height: auto;
            max-width: 300px;
        }
    
        
        .shimmer-qrcode {
            width: 265px;
            height: 265px;
        }
    }

    .box-copy-code {
        max-width: 300px;
        width: 100%;
        margin-bottom: 45px;

        h2 {
            margin-bottom: 30px;
        }
    }
    
    .code-to-copy {
        word-wrap: break-word !important;
    }
    
    @media (max-width: 1199.98px) {
    
        h1 {
            font-size: 24px;
            line-height: 34px;
        }
    
        .info-pix {
            font-size: 16px;
            margin-bottom: 25px;
        }
        
        .box-qrcode {
            max-width: 200px;
            margin-bottom: 25px;
    
            .qrCode {
    
            }
            .code-to-copy {
    
            }
    
            .shimmer-qrcode {
                width: 164px;
                height: 164px;
            }
        }    
    }
}