.accordion-card {
  .accordion-item {
    .accordion-button {
      padding: 0;
    }

    .accordion-body {
      padding: 0;
    }

    .payment-accordion {

      .accordion-button::after {
        display: none;
      }

      .accordion-button.collapsed {
        border-top: none;
        padding: 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}