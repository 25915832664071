@import "variables";

@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

@import
    "components/common",
    "components/button",
    "components/carousel",
    "components/cards",
    "components/form",
    "components/modal",
    "components/text",
    "components/voucher",
    "components/shimmer",
    "components/accordion",
    "components/dropdown"
;