.btn {
    padding: 11px 24px;
    min-height: 48px;
    border-radius: 62px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
}

.btn-sm {
    padding: 9px 24px;
    min-height: 36px;
    border-radius: 40px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
}

.btn-primary {
    background-color: var(--bs-bg-0800);
    border-color: var(--bs-bg-0800);
    color: var(--bs-text-0800);

    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-0800);
        border-color: var(--bs-hover-0800);
        box-shadow: none !important;
        color: var(--bs-text-0800);
    }

    @include media-breakpoint-down(xl) {
        padding: 11px 13px;
        font-size: 18px;
        line-height: 18px;
    }
}

.btn-secondary {
    background-color: var(--bs-bg-button);
    border-color: var(--bs-bg-button);
    color: var(--bs-text-button);

    &:hover,
    &:active,
    &:focus {
        background-color: var(--bs-hover-button);
        border-color: var(--bs-hover-button);
        box-shadow: none !important;
        color: var(--bs-text-button);
    }
}

.btn-bank-selected {
    .btn-bank {
        padding: 4px;
        width: 64px;
        height: 50px;
        margin: 6px;

        display: flex;
        align-items: center;
        background: var(--bs-white);
        border: 1px solid var(--bs-card-payment-border);
        box-sizing: border-box;
        border-radius: 10px;

        font-size: 16px;
        line-height: 18px;
        color: var(--bs-card-payment-text);
        cursor: pointer;

        &.active {
            animation: fadeIn 1s alternate;
            background: var(--bs-bg-field);
        }

    }

    .onlyLogo {
        padding: 12px 20px;
    }



}

.btn-readmore {
    color: var(--bs-black);
    font-weight: normal;
    font-size: 12px;
    padding: 0;
    line-height: 12px;
    height: auto;
    min-height: auto;
}
