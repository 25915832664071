.card-plans {
    border: 2px solid var(--bs-border-price);
    border-radius: 10px;
    padding: 19px 18px;
    margin: 31px auto 19px;
    align-items: center;
    width: 300px;

    @include media-breakpoint-down(xl) {
        margin: 16px auto 19px
    }

    h5 {
        font-size: 14px;
        line-height: 18px;
        position: absolute;
        top: -11px;
        width: 160px;
        background-color: white;
        color: var(--bs-text-price);
        text-align: center;
        margin: 0 auto;
    }

    .currency {
        font-size: 24px;
        line-height: 20px;
        font-weight: normal;
        color: var(--bs-text-price);
        display: inline-block;
        padding-top: 26px;
    }

    .value {
        color: var(--bs-text-price);
        font-size: 64px;
        line-height: 70px;
        margin-bottom: 0px;
        position: relative;
    }

    .description {
        color: var(--bs-text-default);
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 0;
        
    }

    @include media-breakpoint-down(xl) {
        
    }
}

.card-payment-method {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    min-height: 83px;
    background: var(--bs-white);
    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;
    border-radius: 10px;

    font-size: 16px;
    line-height: 18px;
    color: var(--bs-card-payment-text);
    cursor: pointer;
}

.card-payment-method-accordion {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    min-height: 83px;
    width: 100%;
    background: var(--bs-white);
    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;
    border-radius: 10px;

    font-size: 16px;
    line-height: 18px;
    color: var(--bs-card-payment-text);
    cursor: pointer;
}

.card-payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--bs-white);
    max-width: 350px;
    border-radius: 10px;
    padding: 16px 70px;
    margin: 0 auto 24px;

    h6 {
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 0;
        line-height: 22px;
    }

    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;
    }
}

.card-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border: 1px solid var(--bs-border-active);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}