.analise-page {
  
  h2 {
    font-size: 24px;
    line-height: 36px;
  }

  .responsive-img{
    max-height: 400px;
  }

  .btn-contact {
    width: 297px;
  }

  .card-payment {
    width: 537px;
  }
}

@media (max-width: 1200px)
{
  
  .analise-page {
    margin-top: 59px;

    h2 {
      font-size: 18px;
      line-height: 26px;
    }

    .btn-contact {
      margin: 10px 0 19px!important;
    }

    .responsive-img {
      max-height: 188px;
    }
  }

  .footer {

    h6 {
      font-size: 12px;
      line-height: 24px;
    }
  }
}