.title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
}

.subtitle {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
}

h1 {
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
}

h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
}

h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
}

h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
}

h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
}

small {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

.currency,
.cents {
    font-weight: normal;
    font-size: 28px;
    line-height: 20px;
}
.currency {
    margin-right: 2px;
}
.value {
    font-weight: bold;
    font-size: 72px;
    line-height: 20px;
}
.cents {
    font-weight: bold;
}

@include media-breakpoint-down(xl) {

    h1 {
        font-size: 18px;
        line-height: 24px;
    }

    .currency,
    .cents {
        font-weight: normal;
        font-size: 24px;
        line-height: 20px;
    }
    .value {
        font-weight: bold;
        font-size: 64px;
        line-height: 20px;
    }

    .descont {
        display: flex;
        justify-content: end;
        margin-right: -2px;  
      }

    @media screen and (min-width: 420px) {
        .descont {
            display: flex;
            justify-content: end;
            margin-right: 27px;
        }
    }
}