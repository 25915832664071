$primary: #4BA68A;
$secondary: #EB5B00;
$success: #198754;
$info: #0dcaf0;
$warning: #fd7e14;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$bgFooter: #F7F5FA;
$bgCarrousel: #4BA68A;
$textCarrousel: #1D1D1D;
$arrowCarrousel: white;
$bgButton: #EB5B00;
$textButton: #FCF8EF;
$hoverButton: #D85400;
$bg0800: #275E4E;
$text0800: #FCF8EF;
$hover0800: #3D8970;
$textPrice: #4BA68A;
$borderPrice: #78F5C9;
$textDefault: #1D1D1D;
$textInfo: #7F8690;
$textLink: #00A988;
$icons: #7F8690;
$separatorVertical: #E0E0E0;
$separatorHorizontal: #F2F2F2;
$cardPaymentBorder: #F2F2F2;
$cardPaymentText: #4F4F4F;
$labelField: #1D1D1D;
$bgField: #FCF8EF;
$borderField: transparent;
$borderActive: #C4C4C4;
$borderError: #F00000;
$borderSuccess: #4BA68A;
$iconsSuccess: #A6FF00;
$linkTerms: #674AFF;
$voucherBorder: #4BA68A;
$bgCardRegister: #4BA68A;
$bgButtonDependent: #275E4E;
$textButtonDependent: #FFFFFF;
$bgButtonDependentRemove: #FCF8EF;
$textButtonDependentRemove: #F00000;
$textDescription: #BCBCBC;
$bgDialog: #00604D;
