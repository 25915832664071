.col.disabled {

    h2 {
        color: #7F8690;
    }

    .validated {
        fill: #A6FF00;
    }
}

.spinner-class{
    margin-top: 38px;
    margin-right: 10px;
}

.voucher-border {

    .spinner-class{
        position: absolute;
        margin: 0;
        top: calc(50% - 8px);
        right: 20px;
    }   
}