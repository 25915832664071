.carousel {
    width: 100%;
    height: 100%;


    .carousel-inner {
        display: flex;
        align-items: center;
        align-content: center;

        .footer-area {
            margin-top: 70px;
        }

        .carousel-item {

            .content {

                @include media-breakpoint-down(xl) {
                    padding: 0 11px;
                }

            }

            img {
                max-width: 615px;
                object-fit: contain;

                @include media-breakpoint-down(xl) {
                    max-height: 159px;
                    width: 100%;
                }
            }

            &:first-child {

                img {
                    max-height: 702px;
                    object-fit: contain;

                    @include media-breakpoint-down(xl) {
                        width: 256px;
                        // margin-top: 40px;
                    }
                }
            }
        }

    }

    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;
    }

}

.carousel-blackfriday{

    .carousel-indicators {

        margin-bottom: 4rem;

        button {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            border: 2px solid var(--bs-hover-button);
            margin-left: 16px;

            &:first-child {
                margin-left: 0;
            }
        }

        .active {
            background-color: var(--bs-hover-button);

        }

        @include media-breakpoint-down(xl) {
            margin-bottom: 5rem;

        }

    }

    .carousel-control-prev {
        display: none;
    }

    .carousel-control-next {
        display: none;

    }

}